// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// Rails.start()
// import "channels"
import 'jquery';

import Turbolinks from "turbolinks"
import '../stylesheets/application.scss'
require.context('../images', true)
require.context('../fonts', true)
//= require jquery
//= require jquery_ujs

Turbolinks.start()

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
